<template>
  <div>
    <b-modal id="modalIntegrations" :title="$t('Detalle de integracion')" modal-class="custom-dialog dialog-800 " :ok-title="$t(`Ok`)" ok-variant="warning"
    no-close-on-esc no-footer ok-only no-close-on-backdrop @close="close" centered>
      <b-tabs>
        <b-tab>
          <template #title>
            <div class="tab-title">
              <span>{{$t('Configuracion original')}}</span>
            </div>
          </template>
          <field-textarea :value="currentOrder" disabled rows="30"></field-textarea>
        </b-tab>
        <!-- <b-tab>
          <template #title>
            <div class="tab-title">
              <span>{{$t('Configuracion modificada')}}</span>
            </div>
          </template>
          <field-textarea :value="responseData" disabled rows="30"></field-textarea>
        </b-tab> -->
      </b-tabs>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['currentOrder'],
  data() {
    return {
      // currentOrder: '',
      loading: {
        order: true,
        total: false
      }
    }
  },
  computed: {
    ...mapGetters({
      order: 'getOrder',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    generalLoading: {
      handler () {
        this.loading.order = !!this.generalLoading.getOrder
      },
      deep: true
    },

    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    },

    order () {
      this.currentOrder = JSON.stringify(this.order, undefined, 4)
    } 
  },

  methods: {
    close () {
      this.$emit('update:order', {})
      this.currentOrder = ''
    }
  }
}
</script>