<template>
  <div>
    <b-modal id="modalTrackingNumberIntegrations" :title="$t('Integraciones de la empresa')" modal-class="custom-dialog dialog-400 " :ok-title="$t(`Cerrar`)" ok-variant="warning"
  no-footer ok-only no-close-on-backdrop @ok="close" centered>
    <table-render :schema="schema" :rows.sync="currentIntegrationToModal" :actions="actions"></table-render>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: ['currentIntegrationToModal'],
  data() {
    return {
      schema: [],
      actions: []
    }
  },
  watch: {},
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.schema = [
        // {label: 'ID', sortable: true, key: 'company_id'},
        // {label: 'Ecommerce', sortable: true, key: 'ecommerce'},
        // {label: 'Referencia', sortable: true, key: 'imported_id'},
        {label: 'Numero de tracking', sortable: true, key: 'tracking_number'},
        // {label: 'Nº de envío', sortable: true, key: 'delivery_id'},  
        {label: 'Acciones', key: 'actions', class: ['text-center']}
      ]
      this.actions = [
        {action: id => this.redirectDetailDelivery(id), icon: 'ExternalLinkIcon', color: 'info', text: 'Detalle de envío' }
      ]
    },

    redirectDetailDelivery(_id) {
      this.m_openWinBlank(`/deliveries/${_id}`)
    },

    close () {
      this.currentIntegrationToModal = ''
      this.$bvModal.hide('modalTrackingNumberIntegrations')
    }
  }
}
</script>